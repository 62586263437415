import './style.scss'

import { withFirebase } from 'providers/firebase'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// import { useHistory } from 'react-router-dom';

import { Modal } from '@shopify/app-bridge-react';

import {
  Page,
  Layout,
  Card,
  Icon,
  Stack,
  Button,
} from '@shopify/polaris';

import {
  LockMajor,
} from '@shopify/polaris-icons';

import Locations from './components/Locations'
import Inventory from './components/Inventory';
import Details from './components/Details'
import LinkedIndicator from './components/LinkedIndicator'

import ModalAll from 'components/ModalAll/ModalAll';
import { articlePath, homeLocation } from 'refs/links';
import { textsTransfer } from 'refs/resource-names'
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { clearInventory } from 'redux/slices/inventory';
import { useParams } from 'react-router-dom';
import { clearTransfer, setTransferDetail, setTransfer } from 'redux/slices/transfer';
import CSVImport from './components/CSVImport';
import Actions from './components/Actions';
import Skeleton from 'layout/components/Skeleton';
import { dbPaths } from 'refs/db-paths';
import InventoryFetcher from 'components/InventoryFetcher/InventoryFetcher';
import styled from 'styled-components';
import { StatusBadge } from 'utils/formatters';
import useFeatures from 'hooks/useFeatures';
import TransferTotals from './components/TransferTotals';
import TransferPrintWrapper, { callPrintTransfer } from 'components/TransferPrintWrapper/TransferPrintWrapper';
import useExportToCSV from 'hooks/useExportToCSV';
import { setDirtyState, setRecoveredState } from "redux/slices/transfer-state";
import useIndexedDb from "hooks/useIndexedDb";
import { generateId } from "utils/helpers";
// import Article from "components/Article/Article";

function TransferEdit(props) {
  const { db, shop, history } = props
  const [csvInfoActive, csvInfoActiveSet] = useState(false)
  const [step, setStep] = useState(0)
  const [loading, loadingSet] = useState(false)
  const [navigationApproved, navigationApprovedSet] = useState(false)
  const [navigationAttempt, navigationAttemptSet] = useState(false)
  const { transfer } = useAppSelector(s => s)

  const csvRef = useRef();

  const { hasFeature, gotoUpgrade } = useFeatures()

  const { exportTransferToCSV } = useExportToCSV()

  const { type, id:paramId } = useParams()

  const dispatch = useAppDispatch()
  const {
    appData: {
      loggingEnabled
    },
    transferState: {
      isDirty,
      isRecovered,
      creating
    }
  } = useAppSelector(s => s)

  const indexedDb = useIndexedDb()


  const { items } = transfer
  const lastStep = 1
  const finishedSteps = step > lastStep

  const cleanUpAndClose = useCallback((location) => {
    dispatch(setDirtyState(false))
    dispatch(clearInventory())
    dispatch(clearTransfer())  
    dispatch(setRecoveredState(false))     
    indexedDb.transfers.delete(transfer.id)
    setTimeout(() => history.replace(location || homeLocation()));
  }, [dispatch, history, indexedDb.transfers, transfer.id])
  
  const handleCreate = useCallback(() => {
    cleanUpAndClose()
  }, [cleanUpAndClose])

  const dbRef = useMemo(() => db.doc(dbPaths.transfers(shop, transfer.id || 'new')), [db, transfer.id, shop])

  const isNew = useMemo(() => paramId === 'new', [paramId])

  const handleNavigationApproved = useCallback(() => navigationApprovedSet(true), [])

  const printTransfer = useCallback(callPrintTransfer,[])

  const cancleDraft = useCallback(async () => {
    dbRef.update({ status: 'canceled', time_canceled: new Date().toISOString() })
    cleanUpAndClose()
  }, [cleanUpAndClose, dbRef])

  const retrieveDraftTransfer = useCallback(async () => {
    loadingSet(true)
    setStep(lastStep + 1)

    dbRef
      .get()
      .then(s => {
        const transfer = s.data() || {}
        dispatch(setTransfer({ ...transfer }))
        loadingSet(false)
      })
  }, [dbRef, dispatch])

  const hasProducts = useMemo(() => Object.keys(items || {}).length > 0, [items])

  useEffect(() => {
    if (isDirty) indexedDb.transfers.put({
      id:transfer.id, 
      value: transfer, 
      new: isNew,
      sessionId: sessionStorage.getItem('sessionId')
    })
  }, [transfer, indexedDb, isDirty, isNew]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (!isDirty || (isDirty && navigationApproved)) {
        return true
      } else {
        navigationAttemptSet(`${location.pathname}${location.search}`)
        return false
      }
    });

    if (navigationApproved && navigationAttempt) {
      unblock()
      navigationAttemptSet(null)
      cleanUpAndClose(navigationAttempt)
    }
    return unblock
  }, [history, navigationApproved, navigationAttempt, hasProducts, dispatch, cleanUpAndClose, isDirty]);

  useEffect(() => {
    if (creating) return
    if (isRecovered) {
      setStep(lastStep + 1)
      dispatch(setDirtyState(true))
      return
    }
    if (isNew && !transfer.id) dispatch(setTransferDetail({ id: generateId() }))
    if (!isNew && transfer.id) retrieveDraftTransfer() 
  }, [creating, dispatch, isNew, isRecovered, retrieveDraftTransfer, transfer.id]);

  const pageTitle = useMemo(() => {
    const title = `${transfer.name ? `${transfer.name} - ` : ''} ${textsTransfer[type].title}`
    return (
      <Stack alignment="center">
        <Stack.Item>{title}</Stack.Item>
        <StatusBadge status={transfer.status} />
      </Stack>
    )
  }, [transfer.name, transfer.status, type])

  const isDraft = useMemo(() => transfer.status === 'draft', [transfer.status])

  const uploadCSV = useCallback(() => csvRef.current.uploadCSV(), [])

  const toggleCsvInfo = useCallback(() => csvInfoActiveSet(x => !x), [])

  useEffect(() => {
    if (!transfer.type) dispatch(setTransferDetail({ type }))
  }, [dispatch, transfer.type, type]);

  const modalValues = useMemo(() => {
    return isRecovered 
      ? {
        title: "Recovered transfer will be discarded",
        message: "If you leave this page, this transfer will be permenantly discarded. Are you sure you want to continue?",
        actionText: 'Discard recovered transfer',
      }
      : isNew
        ? {
          title: "Your transfer will be discarded",
          message: "If you leave this page, all information you have entered will be discarded. Are you sure you want continue?",
          actionText: 'Discard transfer',
        }
        : {
          title: "Your changes will be discarded",
          message: "If you leave this page, all changes you have entered will be discarded. Are you sure you want to discard your changes?",
          actionText: 'Discard changes',
        }
  }, [isNew, isRecovered])

  if (loading) return <Skeleton.Transfer />

  return (
    <TransferPrintWrapper>
    <Page
      fullWidth={finishedSteps}
      title={pageTitle}
      breadcrumbs={[{ content: 'Home', url: homeLocation(), }]}
      secondaryActions={finishedSteps
        ? [
          ...(loggingEnabled ? [{content: 'Logs', url: '/logs' }] : [] ),
          ...(!isNew ? [{content: 'Print', onAction: printTransfer}] : [] ),
          
        ]
        : []
      }
      actionGroups={
        finishedSteps
          ? [
            {
              title: 'Import',
              actions: [
                {
                  content: 'CSV import',
                  onAction: hasFeature('csv') ? uploadCSV : () => gotoUpgrade('csv'),
                  suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
                },
                {
                  content: 'CSV learn more',
                  url: "https://automatecommerce.zendesk.com/hc/en-us/articles/4579903666831",
                  external: true,
                }
              ]
            },
            ...(!isNew
              ? [{
              title: 'Export',
              actions: [
                {
                  content: 'Export to CSV (for Excel, Numbers, Sheets etc)',
                  onAction: () => exportTransferToCSV(true),
                  suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
                },
                {
                  content: 'Export to CSV (plain csv)',
                  onAction: () => exportTransferToCSV(false),
                  suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
                }
  
              ]
            }]
            : []
            )
          ]
          : []
      }
    >
      
      {/* <Modal
        src={articlePath({id: '4579903666831'})}
        open={csvInfoActive}
        size="Large"
        onClose={() => csvInfoActiveSet(false)}
      /> */}

      
      {/* <ui-modal id="modal-csv-more-info" variant="max">
        <Article id="4579903666831" active={csvInfoActive}/>
        <ui-title-bar>
          <button variant="primary">Primary action</button>
          <button>Secondary action</button>
        </ui-title-bar>
      </ui-modal> */}

      <InventoryFetcher />
      <CSVImport ref={csvRef}/>

      {!!navigationAttempt &&
        <ModalAll
          open={!!navigationAttempt}
          title={modalValues.title}
          message={modalValues.message}
          primaryAction={{ content: modalValues.actionText, onAction: handleNavigationApproved, destructive: true }}
          secondaryActions={[{ content: 'Cancel', onAction: () => navigationAttemptSet(null), }]}
        />

      }
      <Layout>
        {finishedSteps &&
          <Layout.Section>
            <Inventory/>
          </Layout.Section>
        }

        <Layout.Section secondary>
          {finishedSteps &&
            <Actions
              disabled={!hasProducts}
              onCreate={handleCreate}
            />
          }
          <Card title={finishedSteps && 'Overview'}>
            {finishedSteps && <TransferTotals/>}

            {(transfer.parent || transfer.child) &&
              <Card.Section title="Split transfer">
                <LinkedIndicator/>
              </Card.Section>
            }
            <Locations
              transferStep={step}
              finishedSteps={finishedSteps}
              onGotoStep={setStep}
            />
          </Card>
          {finishedSteps &&
            <Details/>
          }
          {finishedSteps && isDraft &&
            <CancelButtonWrap>
              <Button
                plain
                fullWidth
                destructive
                onClick={cancleDraft}
              >Cancel draft</Button>
            </CancelButtonWrap>
          }
        </Layout.Section>

      </Layout>
    </Page>
    </TransferPrintWrapper>

  );
}

const CancelButtonWrap = styled.div`
  margin-top: 2rem;
`

export default withFirebase(TransferEdit)
